import { EMAIL_ADDRESSES } from 'src/config';

import type { NavigationSection } from './types';

export const NAVIGATION_LINKS: {
  upload: NavigationSection;
  edit: NavigationSection;
  manage: NavigationSection;
  share: NavigationSection;
} = {
  upload: {
    title: 'Upload',
    links: [
      { label: 'Upload video', href: '/tools/upload-video' },
      { label: 'Clip from URL', href: '/tools/clipper' },
      { label: 'Backup video', href: '/backup-video' },
    ],
  },
  edit: {
    title: 'Edit',
    links: [
      { label: 'Crop video', href: '/tools/crop-video' },
      { label: 'Mute video', href: '/tools/mute-video' },
      { label: 'Merge video', href: '/merge-video' },
      { label: 'Trim video', href: '/tools/trim-video' },
      { label: 'Rotate video', href: '/tools/rotate-video' },
      { label: 'Compress video', href: '/tools/compress-video' },
    ],
  },
  manage: {
    title: 'Manage',
    links: [
      { label: 'Simple video manager', href: '/video-manager' },
      {
        label: 'Video privacy',
        href: '/share-video/video-privacy',
      },
      {
        label: 'Password protected video',
        href: '/share-video/password-protect',
      },
    ],
  },
  share: {
    title: 'Share',
    links: [
      { label: 'Share video link', href: '/share-video' },
      { label: 'Send multiple videos', href: '/tools/send-multiple-videos' },
      { label: 'Embed video', href: '/tools/embed-video' },
      { label: 'Download video', href: '/download-video' },
      {
        label: 'Ad free video player',
        href: '/share-video/ad-free-video-player',
      },
    ],
  },
};

const TOOLS_LINKS = [
  { label: 'Upload video', slug: '/tools/upload-video' },
  { label: 'Clip from URL', slug: '/tools/clipper' },
  { label: 'Simple video manager', slug: '/video-manager' },
  { label: 'Backup video', slug: '/backup-video' },
  { label: 'Crop video', slug: '/tools/crop-video' },
  { label: 'Mute video', slug: '/tools/mute-video' },
  { label: 'Merge video', slug: '/merge-video' },
  { label: 'Trim video', slug: '/tools/trim-video' },
  { label: 'Rotate video', slug: '/tools/rotate-video' },
  { label: 'Compress video', slug: '/tools/compress-video' },
  { label: 'Share video link', slug: '/share-video' },
  { label: 'Send multiple videos', slug: '/tools/send-multiple-videos' },
  { label: 'Embed video', slug: '/tools/embed-video' },
  { label: 'Download video', slug: '/download-video' },
  {
    label: 'Video privacy',
    slug: '/share-video/video-privacy',
  },
  {
    label: 'Password protected video',
    slug: '/share-video/password-protect',
  },
  { label: 'Ad free video player', slug: '/share-video/ad-free-video-player' },
];

export const FOOTER_LINKS: {
  company: NavigationSection;
  product: NavigationSection;
  tools: NavigationSection;
  support: NavigationSection;
} = {
  company: {
    title: 'Company',
    links: [
      { label: 'Blog', href: '/blog' },
      { label: 'Privacy', href: 'https://privacy.streamable.com' },
      { label: 'Terms', href: 'https://terms.streamable.com' },
      {
        label: 'Community guidelines',
        href: 'https://streamable.com/community-guidelines',
      },
      {
        label: 'Law Enforcement guidelines',
        href: 'https://streamable.com/guidelines-for-law-enforcement',
      },
      {
        label: 'Transparency Report',
        href: 'https://streamable.com/transparency-report-H2-2023.pdf',
      },
      {
        label: 'Open Source Libraries',
        href: '/open-source-libraries',
      },
    ],
  },
  product: {
    title: 'Product',
    links: [
      { label: 'Pricing', href: '/pricing' },
      { label: 'Log in', href: '/login' },
      { label: 'Sign up', href: '/signup' },
    ],
  },
  tools: {
    title: 'Tools',
    links: TOOLS_LINKS.map((toolLink) => ({
      ...toolLink,
      href: toolLink.slug,
    })),
  },
  support: {
    title: 'Support',
    links: [
      {
        label: 'Help center',
        href: 'https://streamableapp.zendesk.com/hc/en-us',
      },
      {
        label: 'Contact',
        onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          if (window.zE) {
            window.zE('webWidget', 'open');
          } else if (window.Beacon) {
            window.Beacon('open');
          } else {
            window.location.href = `mailto:${EMAIL_ADDRESSES.support}`;
          }
        },
      },
    ],
  },
};
